import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { link } from 'fs';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  picture: string;
  usuarioId: string;

  tipoTema: string = 'Claro';
  posicaoTema: string = 'start';

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Default',
    },
    // {
    //   value: 'orange',
    //   name: 'getpush'
    // }
  ];
  currentTheme = 'default';

  userMenu = [
    { title: 'Perfil' },
    { title: 'Sair', link: '/auth/logout' }
  ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private authService: NbAuthService) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    this.authService.onTokenChange()
    .subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
        this.user.picture = "../../../../assets/images/" + this.user.usuarioId.toUpperCase() + ".png";
        sessionStorage.setItem('tipoUsuario', this.user.tipoUsuario);
        sessionStorage.setItem('usuarioId', this.user.usuarioId);
        this.usuarioId = this.user.usuarioId
        // this.excluirListaNegocioCache();
      }

    });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  mudarTema() {
    if (this.tipoTema == 'Claro') {
      this.themeService.changeTheme('dark');
      this.tipoTema = 'Escuro';
      this.posicaoTema = 'end'
    } else {
      this.tipoTema = 'Claro';
      this.posicaoTema = 'start'
      this.themeService.changeTheme('default');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  excluirListaNegocioCache() {
    caches.open(`negocio-cache-${this.usuarioId}`).then(function(cache) {
      cache.delete(`/negocio-cache-${this.usuarioId}`).then(function(success) { });
    });
  }
}
