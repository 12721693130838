import { Component, ViewEncapsulation } from "@angular/core";
import { NbLoginComponent } from "@nebular/auth";

@Component({
    selector: 'ngx-login',
    styleUrls: ['./login.component.scss'],
    templateUrl: './login.component.html',
    encapsulation: ViewEncapsulation.None
  })
  export class NgxLoginComponent extends NbLoginComponent {
    showPassword = true;
    provider: string = '';

    user: any = {};
    hide = true;

    getInputType() {
      if (this.showPassword) {
        return 'text';
      }
      return 'password';
    }

    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    }

  }
